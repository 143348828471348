var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "control" },
    [_c("ControlModels"), _c("ControlPanel"), _c("ControlConfigure")],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }