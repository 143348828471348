var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "RealTimeView" },
    [
      _vm.mShow
        ? _c(
            "el-dialog",
            {
              staticClass: "dialogView",
              attrs: { "append-to-body": true, visible: _vm.mShow, top: "8vh" },
              on: {
                "update:visible": function ($event) {
                  _vm.mShow = $event
                },
              },
            },
            [
              _c("phone-ctn", { attrs: { head: false } }, [
                _vm.mShow
                  ? _c("iframe", {
                      ref: "iframeView",
                      staticClass: "screen",
                      attrs: {
                        id: "cover",
                        scrolling: false,
                        src: _vm.iframeUrl,
                      },
                      on: { load: _vm.load },
                    })
                  : _vm._e(),
              ]),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }