var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "p10" },
    [
      _c(
        "configure-item",
        { attrs: { label: "全局背景色" } },
        [
          _c("config-color-picker", {
            model: {
              value: _vm.config.backgroundColor,
              callback: function ($$v) {
                _vm.$set(_vm.config, "backgroundColor", $$v)
              },
              expression: "config.backgroundColor",
            },
          }),
        ],
        1
      ),
      _c("schema-string", {
        attrs: {
          label: "页面标题",
          options: { maxLength: 30, placeholder: "请输入内容 最多30个字" },
        },
        model: {
          value: _vm.config.pageTitle,
          callback: function ($$v) {
            _vm.$set(_vm.config, "pageTitle", $$v)
          },
          expression: "config.pageTitle",
        },
      }),
      _c("schema-string", {
        attrs: {
          label: "分享标题",
          options: { maxLength: 10, placeholder: "请输入内容 最多10个字" },
        },
        model: {
          value: _vm.config.shareTitle,
          callback: function ($$v) {
            _vm.$set(_vm.config, "shareTitle", $$v)
          },
          expression: "config.shareTitle",
        },
      }),
      _c("schema-string", {
        attrs: {
          label: "分享内容",
          options: { maxLength: 20, placeholder: "请输入内容 最多20个字" },
        },
        model: {
          value: _vm.config.shareContent,
          callback: function ($$v) {
            _vm.$set(_vm.config, "shareContent", $$v)
          },
          expression: "config.shareContent",
        },
      }),
      _c("schema-upload", {
        attrs: { label: "分享小图" },
        model: {
          value: _vm.config.shareImg,
          callback: function ($$v) {
            _vm.$set(_vm.config, "shareImg", $$v)
          },
          expression: "config.shareImg",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }