var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "shape" },
    [
      _vm.data.component != "waiting" && _vm.data.height > 0
        ? _c(
            "div",
            {
              staticClass: "shape-tab",
              style: [_vm.getCusStyle(_vm.data)],
              on: {
                click: function ($event) {
                  return _vm.setcurComponent(_vm.data)
                },
              },
            },
            [
              _vm.control.curWidget && _vm.control.curWidget.id == _vm.data.id
                ? [
                    _c("i", {
                      staticClass: "icon el-icon-delete tab-icon f16",
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.delComponent(_vm.data.id)
                        },
                      },
                    }),
                  ]
                : _c("span", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.data.routine.modelName
                            ? _vm.data.routine.modelName
                            : _vm.data.name
                        )
                    ),
                  ]),
            ],
            2
          )
        : _vm._e(),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }