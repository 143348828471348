var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "topBar" }, [
        _c("div", { staticClass: "page-info" }, [
          _c("h2", [_vm._v(_vm._s(_vm.activity.name))]),
          _c("span", [_vm._v(_vm._s(_vm.activity.creator.name))]),
          _c("span", [_vm._v(_vm._s(_vm.activity.activity_id))]),
        ]),
        _c(
          "div",
          [
            _c(
              "el-button",
              {
                attrs: { size: "small ml10", type: "primary" },
                on: {
                  click: function ($event) {
                    _vm.show = true
                  },
                },
              },
              [_vm._v("实时预览")]
            ),
            _c(
              "el-button",
              {
                attrs: { size: "small", type: "primary" },
                on: { click: _vm.openSave },
              },
              [_vm._v("保存")]
            ),
            _c(
              "el-button",
              { attrs: { size: "small ml10" }, on: { click: _vm.back } },
              [_vm._v("退出")]
            ),
          ],
          1
        ),
      ]),
      _c("real-timeView", {
        attrs: { show: _vm.show },
        on: {
          "update:show": function ($event) {
            _vm.show = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }