var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "control-configure" },
    [
      _vm.control.curWidget
        ? _c(
            "configure-decorate",
            {
              key: _vm.control.curWidget.id,
              attrs: { title: _vm.control.curWidget.name },
            },
            [
              _c("schema-template", {
                key: _vm.curSchema.id,
                attrs: { schema: _vm.curSchema },
                model: {
                  value: _vm.control.curWidget,
                  callback: function ($$v) {
                    _vm.$set(_vm.control, "curWidget", $$v)
                  },
                  expression: "control.curWidget",
                },
              }),
            ],
            1
          )
        : _c(
            "configure-decorate",
            { attrs: { title: "全局配置" } },
            [_c("page-config")],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }